///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$max-width: 1024px !default;
$max-width-large: 1280px !default;
$width-xsmall: 375px !default;
$admin-toolbar-height: 61px !default;
$header-height: 68px !default; // used to offset anchors, etc.
$header-nav-height: 48px !default; // used to offset anchors, etc.
$account-wrapper-height: 39px !default; // used to reduce top space.
$header-height--mobile: 50px !default; // used to offset anchors, etc.
$account-wrapper-mobile: 45px !default; // used to reduce top space for mobile.
$mpp-max-width: 1024px !default; // this is product image width x3 plus margins
$spp-max-width: 1366px !default; // SD SPP max width
$spacing: 50px !default; // standard margin used sitewide
$spacing--mobile: 25px !default; // standard mobile margin used sitewide
$font-family-knockout: 'Knockout 49 Web', arial, sans-serif !default;
$bundle-bg-height: 770px;
$bundle-desktop-image-size: 250px;
$bundle-fixed-cta-height: 54px; // line-height + padding
$bundle-overlay-max-width: 700px;
$offer-banner-height: 46px;
$badge-default-width: 185px;

$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color-success-mark: #07bb5b !default;
$color-green: #090 !default;
// Search
// @todo cleanup
$mobile_search_header_height: 60px !default;
$pc-search-width: 551px !default;

// Typography
// @todo updated for new styleguide 7/21/16 - needs testing
// $base-font-size: 16px !default;
// $base-line-height: 1.6em !default;
// @note we removed !default to avoid overrides
$base-font-size: 13px;
$base-line-height: 17px;
$letter-spacing: 1px;

// Common elements
$border: 1px solid $color-border;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// Redesign variable
$cr21: false !default;
$opc_update: false !default;
$sign-in-up_update: false !default;
$sign-in-up_container: false !default;
$checkout-header-signin_show: false !default;

$bp--large-up: 769px;
$bp--medium-up: 641px;
$ldirection: left !default;
$rdirection: right !default;
$text-direction: ltr !default;
$color-black: #000 !default;
$color-gray: #696969 !default;
$color-medium-gray: #b9bec1;
$color-light-gray: #ccc !default;
$color-lightest-gray: #cbcbcb !default;
$color-silver: #c3c3c3 !default;
$color-nobel-gray: #949494 !default;
$color-smoke-white: #f4f4f4 !default;
$color-black-2opacity: rgba(0, 0, 0, 0.2);
$color-black-4opacity: rgba(0, 0, 0, 0.4) !default;
$color-black-55opacity: rgba(0, 0, 0, 0.55);
$color-black-9opacity: rgba(0, 0, 0, 0.9) !default;
$color-black-64opacity: rgba(0, 0, 0, 0.64) !default;
$color-white-50opacity: rgba(255, 255, 255, 0.5) !default;
$color-bundle-pink: #e55c79;
$color-bundle-gray: #757575;
$color-sharp-black: #111111;
$primer-story-bg-color: #f8f8fa !default;
$color-off-white: #dcdcdc !default;
$color-dark-red: #c00000 !default;
$color-light-blue: #1877f2 !default;
$color-lite-gray: #f7f7f7;
$color-off-gray: #d7d7d7;
// System colors
$color-focus: #4d90fe;

// Colors for error messaging
$color-success-mark-dark: #087443 !default;
$error-bg-color: #fff !default;
$error-color: #ba071a !default;
$error-border-color: #ba071a !default;
$color-core-black: #221f20 !default;
$color-error-msg: #a80000 !default;
$color-success-msg: #087443 !default;
$color-threshold-msg: #b54708 !default;

// Typography variables
// Neue Haas Grotesk Display Pro
$NeueHaasGrotesk: 'Neue Haas Grotesk', sans-serif !default;

// Sign in/Sign up redesign
$NeueHaasGroteskDisplayStd: 'Neue Haas Grotesk Display Std' !default;

// Base styles: each typeface has optimal type treatment that remains consitent
// across the site.
// @setup new brand - examples of how to change to values using web fonts
// $font--heading: "Copperplate Gothic Std 32 AB", sans-serif;
// $font--subheading: "ITC Franklin Gothic", sans-serif;
// $font--text: "Adobe Caslon Pro", serif;
// $font--cta: "Typewriter Elite MT Std", serif;
// $font--typewriter: "Typewriter Elite MT Std", serif;

// smashbox base fonts
//
// Courier Prime
//
// Knockout styles
// Knockout 29 Web = Knockout No. 29 Junior Lightweight
// - better for normal type
// Knockout 47 Web = Knockout No. 47 Bantamweight
// - condensed, bold
// Knockout 49 Web = Knockout No. 49 Lightweight
// - better for headlines

$font--serif: Times, 'Times New Roman', serif !default; // no serif for sbx yet
$font--sans: Arial, sans-serif !default;
$font--sans: Arial, sans-serif !default;
$font--mono: 'Courier Prime', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace !default;
$font--text: 'Courier Prime', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace !default;
$font--link: $font--text;

// primarily for h1 in heroes
$font--heading-display: 'Knockout 47 Web', arial, sans-serif !default;
// SPP nugget
$font--subheading: 'Knockout 29 Web', arial, sans-serif !default;
// most widely used
$font--subheading-alt: 'Knockout 49 Web', arial, sans-serif !default;
// easier to remember name
$font--heading-main: 'Knockout 49 Web', arial, sans-serif !default;

// @todo cleanup for styleguide
$font--cta: $font--sans !default;

$font--helvetica: Helvetica, Arial, sans-serif !default;
$font--helvetica-neue: 'Helvetica Neue', Arial, sans-serif !default;
$font--lackline-italic: lack-line-italic, Arial, sans-serif !default;
$font--lack-regular: lack-regular, Arial, sans-serif !default;

// Adding variable for the new fonts
// @todo remove $font--Neue-Haas-Grotesk-Display-Black later
$font--Neue-Haas-Grotesk-Display-Black: 'Neue Haas Grotesk Display Black', sans-serif !default;
$font--freight-book-Regular: 'Freight Book Regular', arial, sans-serif !default;
$font--Freight-Disp-Pro-Black-Regular: 'Freight DispPro Black Regular', sans-serif !default;

// Akkurat fonts
$font--akkurat-regular: 'AkkuratPro Regular', arial, sans-serif !default;
$font--akkurat-bold: 'AkkuratPro Bold', arial, sans-serif !default;
$font--akkurat-bold-italic: 'AkkuratPro BoldItalic', arial, sans-serif !default;
$font--akkurat-italic: 'AkkuratPro Italic', arial, sans-serif !default;
$font--akkurat-light: 'AkkuratPro Light', arial, sans-serif !default;
$font--akkurat-light-italic: 'AkkuratPro LightItalic', arial, sans-serif !default;

// VCR OSD Mono
$font--vcr-osd-mono: 'VCR OSD Mono', arial, sans-serif !default;

// extra wide breakpoints - used for hero fonts only
$hero-wide-up: 1280px !default;
$hero-x-wide-up: 1526px !default;
$hero-xx-wide-up: 1884px !default;
