// Colors from SBX styleguide
// How to use color variables properly:
// http://sachagreif.com/sass-color-variables/

// ---------------------------------
// DESCRIPTIVE color variable names - try not to use in SCSS
//
// * set here as defaults to be used in functional names below
// ---------------------------------
$black: #000 !default;
$white: #fff !default;
$red: #e20400 !default;
$green: #07bb5b !default;
$dark-gray: #696969 !default;
$medium-gray: #999 !default;
$medium-red: #e20400 !default;
$light-gray: #f4f4f4 !default;
$dark-red: #bb0e20 !default;
$pink: #f66cb6 !default;
$dark-pink: #960d4a !default;
$salmon: #f9e7e5 !default;
$lighter-gray: #f5f5f5 !default;
$blue: #005288 !default;
$light-medium-gray: #a7a5a6 !default;
$light-blue: #4267b2 !default;
$light-pink: #e05e89;
$medium-pink: #e13672;
$dark-medium-gray: #888;
$gray: #ccc;
$lighter-solid-gray: #e3e3e3;
$medium-black: #191919 !default;
$smashbox-red: #d50032 !default;
$smashbox-dark-grey: #6a6a6a !default;
$smashbox-light-grey: #f1f1f1 !default;
$smashbox-fearless: #960e48 !default;
$smashbox-fierce: #ba071a !default;
$smashbox-fab: #dd4a92 !default;

// ---------------------------------
// FUNCTIONAL variable names - USE IN SCSS
//
// * allows flexibility in case sitewide designs change
// ---------------------------------

$color-black: $black;
$color-white: $white;
$color-red: $red;
$color-sbx-red: $smashbox-red;
$color-sbx-dgrey: $smashbox-dark-grey;
$color-sbx-lgrey: $smashbox-light-grey;
$color-sbx-fearless: $smashbox-fearless;
$color-sbx-fierce: $smashbox-fierce;
$color-sbx-fab: $smashbox-fab;
$color-dark-red: $dark-red;
$color-pink: $pink;
$color-dark-pink: $dark-pink;
$color-salmon: $salmon;
$color-light-gray: $lighter-gray;
$color-blue: $blue;
$color-light-med-gray: $light-medium-gray;
$color-dark-gray: $dark-medium-gray;
$color-gray: $gray;
$color-solid-gray: $lighter-solid-gray;
$color-medium-black: $medium-black;

// STANDARD GRAY COLORS to be used sitewide for borders and bgs
// All Text Form Box Outlines (including arrows); Rule colors (separators under headers, etc.)
$color-border: $dark-gray;

// Form placeholder; H4 elements
$color-text-gray: $medium-gray;
$color-text-grey: $color-text-gray;

// All light gray backgrounds (Product images, checkout, my account boxes)
$color-bg: $light-gray;

// general
$color-text: $black;
$color-link: $black;
$color-link-hover: $black;

// form colors
$color-form-grey: $dark-gray; // replace these to use the basic
$color-form-grey-alt: $dark-gray; // replace to use the basic
$color-form-focus: $black;
$color-form-placeholder: $medium-gray;
// form borders
$border-form: $dark-gray solid 1px;
$border-form-focus: $black solid 1px;

// validation colors
$color-success: $black;
$color-error: $red;
$color-warning: $black;

// transparent colors (primarily for overlays)
// @todo audit these for final styleguide changes
//
$color-transparent--white: rgba(255, 255, 255, 0.7) !default;
$color-transparent--black: rgba(0, 0, 0, 0.25) !default;
$color-transparent--dark-black: rgba(0, 0, 0, 0.33);
$color-overlay--dark: rgba(0, 0, 0, 0.85) !default;
$color-transparent--dark: rgba(0, 0, 0, 0.75);
$color-transparent--dark-gray: rgba(0, 0, 0, 0.8);
$color-transparent--light: rgba(0, 0, 0, 0);
$color-dark--blue: rgba(0, 0, 0, 0.4);
$color-darker--blue: rgba(0, 0, 0, 0.5);

// VK login Colors
$color-facebook: #3c5a99;
$color-vkontakte: #4a76a8;

// appointment booking colors
$ab--color--pantone-orange-21c: #ff5000 !default;
$ab--color--gray: #e6e6e6 !default;
$ab--color--gray--white: #f0f0f0 !default;
$ab--color--gray--lightest: #dbdbdb !default;
$ab--color--gray--lighter: #c7c7c7 !default;
$ab--color--gray-light: #999999 !default;
$ab--color--gray-light_1: #afafaf !default;
$ab--color--gray-1: #bfbfbf !default;
$ab--color--gray-2: #6a6969 !default;
$ab--color--gray-3: #f5f5f5 !default;
$ab--color--gray--dark: #4d4d4d !default;
$ab--color--gray--darker: #333333 !default;
$ab--color--gray--darkest: #292929 !default;
$ab--color--gray--black: #1a1a1a !default;
$ab--color--gray--dropdown: #cccccc !default;
$ab--color--gray-dot-look: #7f7f7f !default;
$ab_color--btn-gray-disabled: $ab--color--gray-light_1;

// primer finder colors
$primer-finder-background-color: #F8F8F8;
