@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  display: none;
  position: relative;
  @include breakpoint($landscape-up) {
    display: block;
    clear: both;
    &--carousel {
      margin-bottom: 0;
    }
  }
  &.inline-active {
    margin-bottom: $spacing;
  }
}

.product-quickshop {
  position: relative;
  .quickshop-style-inline & {
    right: 0;
    z-index: 11;
    position: absolute;
    width: 66.6%;
  }
  &__content {
    background-color: $color-bg;
    position: relative;
    .quickshop-style-inline & {
      padding: 40px 35px 30px;
      min-width: 698px;
      box-sizing: content-box;
      margin: 0;
    }
  }
  // @todo finalize widths or use px padding inside
  &__detail {
    margin: 10px 0 0 0;
    float: right;
    width: 50%;
    min-height: 340px;
  }
  &__more-link {
    display: block;
    padding: 0.5em 0;
    text-decoration: underline;
    text-transform: uppercase;
  }
  &__image {
    margin: 10px 0 0 0;
    width: 50%;
    float: left;
    position: relative;
    min-height: 340px;
    &--single {
      position: relative;
    }
  }
  &__product-social {
    padding-right: 1em;
    a:hover {
      text-decoration: none;
    }
  }
  &__close {
    font-size: 34px;
    height: 34px;
    line-height: 34px;
    position: absolute;
    right: 14px;
    top: 14px;
    &:hover {
      text-decoration: none;
    }
    .qs-overlay-cbox & {
      display: none;
    }
  }
  .qs-display-first &,
  .left-active & {
    left: 0;
    right: auto;
  }
  .select-box__options {
    max-height: 105px; // options are clipped, not a lot of space available
  }
}
// overlay / colorbox QS specific styles
.qs-overlay-cbox {
  background-color: $color-bg;
  #cboxContent {
    margin-top: 0;
  }
  #cboxLoadedContent {
    background-color: $color-bg;
  }
}

// when quickshop is triggered we load a fakie overlay thing
.qs-inline-overlay {
  background: $color-overlay--dark;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
}

.qs-carousel-overlay {
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
}
